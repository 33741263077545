import React from "react";
import { useNavigate } from "react-router-dom";
import { loginAsAdmin } from "../utils/auth";

export default function Login() {
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();

  const handleLogin = () => {
    if (loginAsAdmin(password)) {
      const uniqueId = crypto.randomUUID();
      localStorage.setItem("ID", uniqueId);
      navigate("/admin");
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="w-full max-w-md space-y-4 p-4">
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full rounded bg-gray p-2"
          placeholder="Admin Password"
        />
        <button 
          onClick={handleLogin}
          className="w-full rounded bg-blue p-2"
        >
          Login
        </button>
      </div>
    </div>
  );
} 