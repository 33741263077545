import classNames from "classnames";
import React from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { Tooltip } from "react-tooltip";

export interface Props {
  day: string;
  completions: string[];
  index: number;
  last365Days: string[];
  comments: Record<string, string>;
  logDay?: (day: string) => Promise<void>;
  unlogDay?: (day: string) => Promise<void>;
}

export const HabitCube: React.FC<Props> = ({
  day,
  completions,
  index,
  last365Days,
  comments,
  logDay,
  unlogDay,
}) => {
  const [gotLogged, setGotLogged] = React.useState(false);
  const isFiller = day === "FILLER";
  
  // Check if this is today's cube
  const isToday = React.useMemo(() => {
    if (isFiller) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const cubeDate = new Date(day);
    return cubeDate.getTime() === today.getTime();
  }, [day, isFiller]);

  // Format the tooltip date
  const tooltipDate = React.useMemo(() => {
    if (isFiller) return "";
    const date = new Date(day);
    return date.toLocaleDateString('en-US', { 
      weekday: 'short', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  }, [day, isFiller]);

  React.useEffect(() => {
    if (gotLogged) {
      setTimeout(() => {
        setGotLogged(false);
      }, 2000);
    }
  }, [gotLogged]);

  return (
    <div
      key={day}
      data-tooltip-id={day}
      data-tooltip-content={tooltipDate}
      className={classNames(
        "size-4 cursor-pointer rounded-sm border-[1px] border-transparent",
        {
          "bg-gray hover:bg-light-gray": !isFiller && !completions.includes(day),
          "bg-green-500": completions.includes(day),
          "border-white": isToday,
          "opacity-30": isFiller,
          "ring-2 ring-blue": comments[day],
          "cursor-default": !logDay && !unlogDay,
        }
      )}
      onClick={() => {
        if (isFiller || !logDay || !unlogDay) return;
        if (completions.includes(day)) {
          unlogDay(day);
          setGotLogged(false);
        } else {
          logDay(day);
          setGotLogged(true);
        }
      }}
    >
      {gotLogged && <ConfettiExplosion particleCount={50} />}
      {!isFiller && <Tooltip id={day} />}
    </div>
  );
}; 