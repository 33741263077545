import React from "react";
import { Navigate } from "react-router-dom";
import { AdminHabit } from "../components/AdminHabit/index";
import { CreateHabit } from "../components/modals/CreateHabit";
import { Page } from "../components/Page";
import { useUser } from "../state/user";
import { isAdmin } from "../utils/auth";

export default function Admin() {
  const { habits, updateUserInfo } = useUser();
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  
  // Load habits when component mounts
  React.useEffect(() => {
    updateUserInfo();
  }, []);

  if (!isAdmin()) {
    return <Navigate to="/" replace />;
  }

  return (
    <Page>
      <div className="flex flex-col gap-2 text-center">
        <h1 className="text-6xl font-bold">manage habits</h1>
        <h2 className="text-light-gray">Track and comment on your habits</h2>
      </div>

      <div className="flex w-full max-w-full flex-col gap-2 md:max-w-[750px]">
        <button
          onClick={() => setShowCreateModal(true)}
          className="mb-4 self-end rounded bg-blue px-4 py-2"
        >
          Add Habit
        </button>

        {habits?.map((habit) => (
          <AdminHabit key={habit.id} {...habit} />
        ))}

        {showCreateModal && (
          <CreateHabit
            onClose={() => setShowCreateModal(false)}
            onSuccess={updateUserInfo}
          />
        )}
      </div>
    </Page>
  );
} 