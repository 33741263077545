import React from "react";
import { Habit } from "../components/Habit";
import { Page } from "../components/Page";
import { useUser } from "../state/user";

export default function Home() {
  const { habits, updateUserInfo } = useUser();

  React.useEffect(() => {
    updateUserInfo();
  }, []);

  return (
    <Page>
      <div className="flex flex-col gap-2 text-center">
        <h1 className="text-6xl font-bold">my habits</h1>
        <h2 className="text-light-gray">Daily progress tracking</h2>
      </div>

      <div className="flex w-full max-w-full flex-col gap-2 md:max-w-[750px]">
        {habits?.map((habit) => <Habit key={habit.id} {...habit} />)}
      </div>
    </Page>
  );
}
