import React from "react";
import { api } from "../../utils/api";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

export const CreateHabit: React.FC<Props> = ({ onClose, onSuccess }) => {
  const [name, setName] = React.useState("");
  const [error, setError] = React.useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!name.trim()) return;
    
    try {
      await api.post("/habits/create", { name: name.trim() });
      await onSuccess();
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create habit');
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md rounded-lg bg-dark-gray p-6">
        <h2 className="mb-4 text-xl font-bold">Create New Habit</h2>
        {error && <p className="mb-4 text-red-500">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Habit name"
            className="w-full rounded bg-gray p-2"
            autoFocus
          />
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="rounded bg-gray px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded bg-blue px-4 py-2"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}; 