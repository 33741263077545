import classNames from "classnames";
import React from "react";
import { HabitType } from "../state/user";
import { api } from "../utils/api";
import { calculateStreaks, getLast365Days } from "../utils/utils";
import { HabitCube } from "./HabitCube/index";
import type { Props as HabitCubeProps } from "./HabitCube/index";

interface Props extends HabitType {
  isAdmin?: boolean;
}

export const Habit: React.FC<Props> = ({ id, name, completed, comments, isAdmin }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const last365Days = React.useMemo(getLast365Days, []);
  const [completions, setCompletions] = React.useState(completed);
  const { currentStreak } = React.useMemo(
    () => calculateStreaks(completions),
    [completions],
  );
  const [selectedDay, setSelectedDay] = React.useState<string | null>(null);
  const [commentText, setCommentText] = React.useState("");

  // Update local state when prop changes
  React.useEffect(() => {
    setCompletions(completed);
  }, [completed]);

  // Add logging functions for admin mode
  const logDay = async (day: string) => {
    if (!isAdmin) return;
    await api.post("/habits/log", { id, day });
    setCompletions([...completions, day]);
  };

  const unlogDay = async (day: string) => {
    if (!isAdmin) return;
    await api.post("/habits/unlog", { id, day });
    setCompletions(completions.filter(d => d !== day));
  };

  // Add comment functions for admin mode
  const addComment = async (day: string, text: string) => {
    if (!isAdmin) return;
    await api.post("/habits/comment", { id, day, text });
    setSelectedDay(null);
    setCommentText("");
  };

  React.useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollLeft = ref.current.scrollWidth;
  }, []);

  // group days by month
  const months = React.useMemo(() => {
    const monthGroups: Record<string, number> = {};
    let currentMonth = "";
    let currentIndex = 0;

    // Process all days including the last one
    last365Days.forEach((day, index) => {
      if (day === "FILLER") return;
      const date = new Date(day);
      const month = date.toLocaleDateString('en-US', { month: 'short' });
      
      if (currentMonth !== month || index === last365Days.length - 1) {
        monthGroups[month] = index - currentIndex;
        currentMonth = month;
        currentIndex = index;
      }
    });
    return monthGroups;
  }, [last365Days]);

  return (
    <div className="group flex flex-col gap-2 rounded-lg bg-dark-gray p-4 md:max-w-[750px]">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-grow items-center gap-2 overflow-hidden">
          <h3 className="text-xl font-bold">{name}</h3>
          <div
            className={classNames(
              "shrink-0 rounded-lg px-2 py-1 text-xs font-bold",
              {
                "bg-green-500": currentStreak > 0,
                "bg-light-gray": currentStreak === 0,
              }
            )}
          >
            {currentStreak} DAY STREAK
          </div>
        </div>
      </div>

      <div
        className="hide-scrollbar flex flex-col gap-1 overflow-auto"
        ref={ref}
      >
        {/* months */}
        <div className="flex text-ellipsis">
          {Object.entries(months).map(([month, startIndex], i, arr) => {
            const margin = startIndex / 7;
            // Adjust margin for the last month
            const isLastMonth = i === arr.length - 1;
            
            return (
              <span
                key={month}
                style={{
                  marginLeft: `${margin * 13}px`,
                  marginRight: isLastMonth ? '13px' : '0',
                  textAlign: 'center'
                }}
              >
                {month}
              </span>
            );
          })}
        </div>

        {/* grid */}
        <div className="grid w-fit grid-flow-col grid-rows-7 gap-1 overflow-auto">
          {last365Days.map((day, index) => (
            <HabitCube
              key={index}
              day={day}
              index={index}
              completions={completions}
              comments={comments}
              last365Days={last365Days}
              logDay={isAdmin ? logDay : undefined}
              unlogDay={isAdmin ? unlogDay : undefined}
            />
          ))}
        </div>
      </div>

      {/* Comment modal */}
      {selectedDay && isAdmin && (
        <div className="mt-2 space-y-2">
          <textarea 
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            className="w-full rounded bg-gray p-2"
            placeholder="Add a comment..."
          />
          <div className="flex gap-2">
            <button 
              onClick={() => addComment(selectedDay, commentText)}
              className="rounded bg-blue px-4 py-2"
            >
              Save Comment
            </button>
            <button 
              onClick={() => setSelectedDay(null)}
              className="rounded bg-gray px-4 py-2"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
