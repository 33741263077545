import classNames from "classnames";
import React from "react";
import { HabitType, useUser } from "../../state/user";
import { api } from "../../utils/api";
import { Habit } from "../Habit";
import { Trash } from "lucide-react";
import { ConfirmModal } from "../modals/ConfirmModal";

export const AdminHabit: React.FC<HabitType> = (props) => {
  const { deleteHabit, renameHabit } = useUser();
  const [habitName, setHabitName] = React.useState(props.name);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const spanRef = React.useRef<HTMLSpanElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (spanRef.current && inputRef.current) {
      const spanWidth = spanRef.current.offsetWidth;
      inputRef.current.style.width = `${spanWidth + 5}px`;
    }
  }, [habitName]);

  const rename = async () => {
    if (habitName === props.name) return;
    await renameHabit(props.id, habitName);
  };

  return (
    <div className="relative">
      {showDeleteModal && (
        <ConfirmModal
          description="Deleted habits can't be recovered"
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={() => {
            deleteHabit(props.id);
            setShowDeleteModal(false);
          }}
        />
      )}

      <button
        className="absolute right-2 top-2 z-10 min-w-fit cursor-pointer p-2 opacity-0 transition-opacity duration-200 hover:!opacity-100 group-hover:opacity-100"
        onClick={() => setShowDeleteModal(true)}
      >
        <Trash className="size-5 text-red-500" />
      </button>

      <div className="flex items-center" style={{ maxWidth: "calc(100% - 7rem)" }}>
        <span
          ref={spanRef}
          className="invisible absolute max-w-full text-xl font-bold"
          style={{ whiteSpace: "pre" }}
        >
          {habitName || " "}
        </span>

        <input
          ref={inputRef}
          value={habitName}
          onChange={(e) => setHabitName(e.target.value)}
          className="bg-transparent text-xl font-bold outline-none"
          style={{ minWidth: "1ch" }}
          onBlur={rename}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              rename();
              inputRef.current?.blur();
            }
          }}
        />
      </div>

      <Habit 
        {...props} 
        name={habitName}
        isAdmin={true}
      />
    </div>
  );
}; 