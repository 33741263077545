import React from "react";

interface Props {
  children?: React.ReactNode;
}

export const Page: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex min-h-screen flex-col items-center p-8">
      {children}
    </div>
  );
};
