const API_URL = process.env.REACT_APP_API_URL || '/api';

interface APIError {
  status: number;
  message: string;
  details?: any;
}

// Define expected response types
interface HabitResponse {
  habits: Array<{
    id: string;
    name: string;
    completed: string[];
    comments: Record<string, string>;
    created?: number;
  }>;
}

class APIRequestError extends Error {
  status: number;
  details?: any;

  constructor(message: string, status: number, details?: any) {
    super(message);
    this.name = "APIRequestError";
    this.status = status;
    this.details = details;
  }
}

// Add retry logic for better reliability
const fetchWithRetry = async (url: string, options: RequestInit, retries = 3): Promise<Response> => {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const data = await response.json().catch(() => ({}));
        throw new Error(data.message || `HTTP error! status: ${response.status}`);
      }
      return response;
    } catch (error) {
      if (i === retries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, i)));
    }
  }
  throw new Error('Failed to fetch after retries');
};

export class api {
  static async get(url: string): Promise<HabitResponse> {
    const ID = localStorage.getItem("ID");
    console.log("Current ID:", ID);
    if (!ID) throw new Error("No ID found");

    const response = await fetchWithRetry(`${API_URL}${url}`, {
      headers: {
        Authorization: ID,
        "Content-Type": "application/json",
      },
    });

    return response.json();
  }

  static async post(url: string, body: object): Promise<HabitResponse> {
    const ID = localStorage.getItem("ID");
    if (!ID) throw new Error("No ID found");

    const response = await fetchWithRetry(`${API_URL}${url}`, {
      method: "POST",
      headers: {
        Authorization: ID,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    return response.json();
  }
}
