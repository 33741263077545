const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD;

export const isAdmin = () => {
  return localStorage.getItem('isAdmin') === 'true';
};

export const loginAsAdmin = (password: string) => {
  if (password === ADMIN_PASSWORD) {
    localStorage.setItem('isAdmin', 'true');
    return true;
  }
  return false;
};

export const logoutAdmin = () => {
  localStorage.removeItem('isAdmin');
}; 