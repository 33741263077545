export const getLast365Days = (): string[] => {
  const days: string[] = [];
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 364); // Go back 364 days to get 365 total days including today

  // Fill in any missing days at start of week
  const startDay = startDate.getDay();
  for (let i = 0; i < startDay; i++) {
    days.push("FILLER");
  }

  // Add all dates from start to today in ISO format
  const current = new Date(startDate);
  while (current <= today) {
    days.push(formatDate(current));
    current.setDate(current.getDate() + 1);
  }

  // Important: We need to reverse the array to show most recent dates on the right
  return days;
};

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const calculateStreaks = (completed: string[]): { currentStreak: number; longestStreak: number } => {
  if (!completed.length) return { currentStreak: 0, longestStreak: 0 };

  const sortedDates = completed
    .map(date => new Date(date))
    .sort((a, b) => b.getTime() - a.getTime()); // Sort descending

  let currentStreak = 0;
  let longestStreak = 0;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  // Start from most recent date
  let checkDate = new Date(sortedDates[0]);
  checkDate.setHours(0, 0, 0, 0);

  // If most recent completion isn't today, no current streak
  if (checkDate.getTime() !== today.getTime()) {
    return { currentStreak: 0, longestStreak: getLongestStreak(sortedDates) };
  }

  // Count consecutive days
  for (let i = 0; i < sortedDates.length; i++) {
    const date = new Date(sortedDates[i]);
    date.setHours(0, 0, 0, 0);

    if (i === 0 || date.getTime() === checkDate.getTime()) {
      currentStreak++;
      checkDate.setDate(checkDate.getDate() - 1);
    } else {
      break;
    }
  }

  longestStreak = Math.max(currentStreak, getLongestStreak(sortedDates));
  return { currentStreak, longestStreak };
};

// Helper function to calculate longest streak
const getLongestStreak = (sortedDates: Date[]): number => {
  let longest = 0;
  let current = 0;
  let checkDate = new Date(sortedDates[0]);

  for (let i = 0; i < sortedDates.length; i++) {
    const date = new Date(sortedDates[i]);
    date.setHours(0, 0, 0, 0);

    if (i === 0 || date.getTime() === checkDate.getTime()) {
      current++;
      longest = Math.max(longest, current);
      checkDate.setDate(checkDate.getDate() - 1);
    } else {
      current = 1;
      checkDate = new Date(date);
      checkDate.setDate(checkDate.getDate() - 1);
    }
  }

  return longest;
};

export const generateRandomString = (length: number): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log(`"${text}" copied to clipboard`);
    })
    .catch((err) => {
      console.error("Error copying text: ", err);
    });
};

export const syncCode = (code: string) => {
  localStorage.setItem("ID", code);
};

export const parseDate = (dateStr: string): Date => {
  const [_, day, month, year] = dateStr.split(" ");
  const parsedDate = new Date(`${day} ${month} ${year}`);
  if (isNaN(parsedDate.getTime())) {
    throw new Error(`Invalid date format: ${dateStr}`);
  }
  return parsedDate;
};
