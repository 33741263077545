import { create } from "zustand";
import { api } from "../utils/api";

export type HabitType = {
  id: string;
  name: string;
  completed: string[];
  comments: Record<string, string>;
  created?: number;
  isAdmin?: boolean;
};

export interface Store {
  habits: HabitType[];
  updateUserInfo: () => Promise<void>;
  deleteHabit: (id: string) => Promise<void>;
  renameHabit: (id: string, name: string) => Promise<void>;
}

export const useUser = create<Store>()((set) => ({
  habits: [],

  updateUserInfo: async () => {
    const info = await api.get("/habits");
    if (info?.habits) {
      set((state) => ({ ...state, habits: info.habits }));
    }
  },

  deleteHabit: async (id: string) => {
    await api.post("/habits/delete", { id });
    const info = await api.get("/habits");
    if (info?.habits) {
      set((state) => ({ ...state, habits: info.habits }));
    }
  },

  renameHabit: async (id: string, name: string) => {
    await api.post("/habits/rename", { id, name });
    const info = await api.get("/habits");
    if (info?.habits) {
      set((state) => ({ ...state, habits: info.habits }));
    }
  }
}));
